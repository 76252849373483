import { createSlice } from "@reduxjs/toolkit";
const { uploadHtDocument, htDocumentList, htDocumentDelete } = require("redux/service/htServices");

const htCases = [
	{
		api: uploadHtDocument,
		name: "uploadHtDocument",
	},
	{
		api: htDocumentList,
		name: "htDocumentList",
	},
	{
		api: htDocumentDelete,
		name: "htDocumentDelete",
	},
];

let initialState = {};

htCases.forEach((api) => {
	initialState[api.name] = {
		loading: false,
		data: null,
		error: null,
	};
});

export const htSlice = createSlice({
	name: "ht",
	initialState,
	extraReducers: (builder) => {
		htCases.forEach((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload?.data ?? null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].error = null;
					state[cases.name].data = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].error = payload;
					state[cases.name].data = null;
				});
		});
	},
});

export default htSlice.reducer;
