/* eslint-disable no-mixed-operators */
import React, {useCallback, useEffect, useState} from "react";
import {
  Flex,
  Drawer,
  Accordion,
  Image,
  Text,
  Stack,
  Menu,
  Skeleton,
} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {
  IconSettings,
  IconMessageCircle,
  IconArrowsLeftRight,
  IconX,
} from "@tabler/icons-react";
import {useLocation, useNavigate} from "react-router-dom";
import {
  materialIcon,
  priceIcon,
  miniLogo,
  menuIcon,
  customerIcon,
  orderIcon,
  packingSlipIcon,
  supplierIcon,
  materialMenuIcon,
  htIcon,
  calculatorIcon,
  reportIcon,
  settingsIcon,
  notificationIcon,
  userProfileIcon,
  formIcon,
  customerActiveIcon,
  orderActiveIcon,
  packingSlipActiveIcon,
  supplierActiveIcon,
  materialActiveIcon,
  htActiveIcon,
  settingsActiveIcon,
  calculatorActiveIcon,
} from "helpers/images";
import "./style.css";
import LumberCalculatorPopup from "components/popup/LumberCalculator";
import {useDispatch, useSelector} from "react-redux";
import {authLogout, getMe} from "redux/service/authService";
import {notify} from "helpers/global";

function HeaderComponent() {
  const [opened, {open, close}] = useDisclosure(false);
  const data = useSelector((state) => state.auth.getMe.data);
  const [calculatorPopUp, setCalculatorPopUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const pathNameSplit = location?.pathname.split("/")[2];
  const submenus = [
    {
      name: "Materials",
      icons: materialIcon,
      children: [
        {name: "Pallets Products", path: "/admin/materials/pallet"},
        {name: "Woods", path: "/admin/materials/wood"},
        {name: "Fastners", path: "/admin/materials/fastners"},
      ],
    },
    {
      name: "Forms",
      children: [],
      icons: formIcon,
      none: true,
      path: "/admin/form",
    },
    {
      name: "Quotation",
      icons: formIcon,
      children: [
        {name: "Create Quotation", path: "/admin/quotations/create"},
        {name: "Complete Quote", path: "/admin/quotations/Completed"},
        {name: "All Quote", path: "/admin/quotations/all"},
      ],
    },
    {
      name: "Lumber Price",
      icons: priceIcon,
      children: [],
      none: true,
      path: "/admin/lumberprice",
    },
  ];
  const handleCalculator = useCallback(() => {
    setCalculatorPopUp((state) => !state);
  }, []);

  const getMeApi = useCallback(async () => {
    try {
      dispatch(getMe());
    } catch (error) {}
  }, [dispatch]);

  const logoutFn = async () => {
    try {
      const response = await dispatch(authLogout()).unwrap();
      notify(response);
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
      notify(error);
    }
  };

  const navigatePath = (path) => {
    navigate(`admin/${path}`);
  };

  const navigatePathFn = (link) => {
    navigate(link.path);
    close();
  };

  useEffect(() => {
    getMeApi();
  }, [getMeApi]);

  return (
    <div>
      <Flex
        mih={50}
        bg="#bcddff"
        gap="sm"
        justify="space-between"
        align="center"
        direction="row"
        wrap="wrap"
        px={20}
      >
        <Flex align={"center"} justify={"flex-end"} gap={15}>
          <Image
            src={miniLogo}
            width={"60px"}
            className="cP"
            onClick={() => navigate("/admin/dashboard")}
          />
          <div
            style={{
              background: "#1E55A5",
              color: "white",
              padding: "3px 20px 0px 20px",
              cursor: "pointer",
            }}
            onClick={open}
          >
            <Flex
              align={"center"}
              justify={"center"}
              direction={"column"}
              gap={3}
              mih={50}
            >
              <Image src={menuIcon} width={"16px"} />
              <Text size={"sm"}>Menu</Text>
            </Flex>
          </div>
          <div style={{paddingLeft: "20px"}}>
            <Flex
              align={"center"}
              justify={"center"}
              direction={"row"}
              gap={30}
              mih={50}
            >
              <Stack
                align="center"
                spacing={5}
                justify="center"
                className={
                  pathNameSplit === "customer" ||
                  pathNameSplit === "customers" ||
                  pathNameSplit === "quotations"
                    ? "activeClassIcon cP"
                    : "cP"
                }
                onClick={() => navigatePath("customer")}
              >
                <Image
                  src={
                    pathNameSplit === "customer" ||
                    pathNameSplit === "customers" ||
                    pathNameSplit === "quotations"
                      ? customerActiveIcon
                      : customerIcon
                  }
                  width={"15px"}
                />
                <Text
                  size={"sm"}
                  color={
                    pathNameSplit === "customer" ||
                    (pathNameSplit === "customers" && "#1E55A5")
                  }
                >
                  Customers
                </Text>
              </Stack>
              <Stack
                align="center"
                spacing={5}
                justify="center"
                className={
                  pathNameSplit === "order" ? "activeClassIcon cP" : "cP"
                }
                onClick={() => navigatePath("order")}
              >
                <Image
                  src={pathNameSplit === "order" ? orderActiveIcon : orderIcon}
                  width={"15px"}
                />
                <Text
                  size={"sm"}
                  color={pathNameSplit === "order" && "#1E55A5"}
                >
                  Orders
                </Text>
              </Stack>
              <Stack
                align="center"
                spacing={5}
                justify="center"
                className={
                  pathNameSplit === "packing-slip" ? "activeClassIcon cP" : "cP"
                }
                onClick={() => navigatePath("packing-slip")}
              >
                <Image
                  src={
                    pathNameSplit === "packing-slip"
                      ? packingSlipActiveIcon
                      : packingSlipIcon
                  }
                  width={"15px"}
                />
                <Text
                  size={"sm"}
                  color={pathNameSplit === "packing-slip" && "#1E55A5"}
                >
                  Packing Slip
                </Text>
              </Stack>
              <Stack
                align="center"
                spacing={5}
                justify="center"
                className={
                  pathNameSplit === "suppliers" ||
                  pathNameSplit === "purchase-order"
                    ? "activeClassIcon cP"
                    : "cP"
                }
                onClick={() => navigatePath("suppliers")}
              >
                <Image
                  src={
                    pathNameSplit === "suppliers" ||
                    pathNameSplit === "purchase-order"
                      ? supplierActiveIcon
                      : supplierIcon
                  }
                  width={"15px"}
                />
                <Text
                  size={"sm"}
                  color={
                    pathNameSplit === "suppliers" ||
                    (pathNameSplit === "purchase-order" && "#1E55A5")
                  }
                >
                  Suppliers
                </Text>
              </Stack>
              <Stack
                align="center"
                spacing={5}
                justify="center"
                className={
                  pathNameSplit === "materials" ||
                  pathNameSplit === "all-materials"
                    ? "activeClassIcon cP"
                    : "cP"
                }
                onClick={() => navigatePath("materials")}
              >
                <Image
                  src={
                    pathNameSplit === "materials" ||
                    pathNameSplit === "all-materials"
                      ? materialActiveIcon
                      : materialMenuIcon
                  }
                  width={"15px"}
                />
                <Text
                  size={"sm"}
                  color={
                    pathNameSplit === "materials" ||
                    (pathNameSplit === "all-materials" && "#1E55A5")
                  }
                >
                  Materials
                </Text>
              </Stack>

              <Stack
                align="center"
                spacing={5}
                justify="center"
                className={pathNameSplit === "ht" ? "activeClassIcon cP" : "cP"}
                onClick={() => navigatePath("ht")}
              >
                <Image
                  src={pathNameSplit === "ht" ? htActiveIcon : htIcon}
                  width={"15px"}
                />
                <Text size={"sm"} color={pathNameSplit === "ht" && "#1E55A5"}>
                  HT
                </Text>
              </Stack>

              <Stack
                align="center"
                spacing={5}
                justify="center"
                className={
                  pathNameSplit === "lumber-calculator"
                    ? "activeClassIcon cP"
                    : "cP"
                }
                onClick={(e) => {
                  // e.preventDefault();
                  // handleCalculator();
                  navigate("/admin/lumber-calculator");
                }}
              >
                <Image
                  src={
                    pathNameSplit === "lumber-calculator"
                      ? calculatorActiveIcon
                      : calculatorIcon
                  }
                  // calculatorActiveIcon
                  width={"12px"}
                />
                <Text
                  size={"sm"}
                  color={pathNameSplit === "lumber-calculator" && "#1E55A5"}
                >
                  Lumber Calculator
                </Text>
              </Stack>
              <Stack align="center" spacing={5} justify="center" className="cP">
                <Image src={reportIcon} width={"15px"} />
                <Text size={"sm"}>Report</Text>
              </Stack>

              <Stack
                align="center"
                spacing={5}
                justify="center"
                className={
                  pathNameSplit === "settings" ? "activeClassIcon cP" : "cP"
                }
                onClick={() => navigatePath("settings")}
              >
                <Image
                  src={
                    pathNameSplit === "settings"
                      ? settingsActiveIcon
                      : settingsIcon
                  }
                  width={"15px"}
                />
                <Text
                  size={"sm"}
                  color={pathNameSplit === "settings" && "#1E55A5"}
                >
                  Settings
                </Text>
              </Stack>
            </Flex>
          </div>
        </Flex>

        <div>
          <Flex
            align={"center"}
            justify={"center"}
            direction={"row"}
            gap={15}
            mih={50}
          >
            <div>
              <Image src={notificationIcon} width={"15px"} />
            </div>
            <div>
              <Text
                size={"sm"}
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {data?.first_name ? (
                  <>Hi, {data?.first_name}</>
                ) : (
                  <Skeleton>Hi, Ranjith</Skeleton>
                )}
              </Text>
            </div>
            <Menu shadow="md" width={200}>
              <Menu.Target className="cP">
                <Image
                  src={
                    data?.profile
                      ? `${process.env.REACT_APP_API_IMAGE_ENDPOINT}/${data?.profile}`
                      : userProfileIcon
                  }
                  width={"25px"}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  icon={<IconSettings size={14} />}
                  onClick={() => {
                    navigate("/admin/settings");
                  }}
                >
                  Settings
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    navigate("/admin/change-password");
                  }}
                  icon={<IconMessageCircle size={14} />}
                >
                  Change Password
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  onClick={logoutFn}
                  icon={<IconArrowsLeftRight size={14} to={"/"} />}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </div>
      </Flex>
      <Drawer
        opened={opened}
        onClose={close}
        title=""
        className="haaderDrawerComponent"
        size={"xs"}
        withCloseButton={false}
        transitionProps={{duration: 300, timingFunction: "linear"}}
      >
        <Flex
          align={"center"}
          justify={"flex-end"}
          gap={5}
          style={{cursor: "pointer"}}
          onClick={close}
          pt={5}
          pb={20}
        >
          <IconX size={13} />
          <Text size={"md"}>Close</Text>
        </Flex>
        <>
          <Accordion variant="filled">
            {submenus.map((link, index2) => (
              <React.Fragment key={index2}>
                <Accordion.Item value={link.name}>
                  <Accordion.Control
                    chevron={link.none}
                    icon={<Image width={"15px"} src={link.icons} />}
                    onClick={() =>
                      link.none === true ? navigatePathFn(link) : null
                    }
                  >
                    {link.name}
                  </Accordion.Control>
                  {link?.children.map((child, index) => (
                    <Accordion.Panel
                      key={index}
                      ml={15}
                      onClick={() => {
                        close();
                        navigate(child.path);
                      }}
                    >
                      <Flex align={"center"} className="cP">
                        <Text size={"md"}>{child.name}</Text>
                      </Flex>
                    </Accordion.Panel>
                  ))}
                </Accordion.Item>
              </React.Fragment>
            ))}
          </Accordion>
        </>
      </Drawer>
      {calculatorPopUp && (
        <LumberCalculatorPopup
          opened={calculatorPopUp}
          onClose={handleCalculator}
        />
      )}
    </div>
  );
}

export default HeaderComponent;
