import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Loader, LoadingOverlay } from "@mantine/core";
import AdminLayout from "components/Layouts";
import NewForm from "NewForm";

const LoginComponent = lazy(() => import("./pages/auth/login"));
const ForgotPassword = lazy(() => import("./pages/auth/forgot-password"));
const ResetPassword = lazy(() => import("./pages/auth/reset-password"));
const ChangePassword = lazy(() => import("./pages/auth/change-password"));

const DashboardComponent = lazy(() => import("./pages/dashboard"));
// customer pages
const CustomerComponent = lazy(() => import("./pages/customer"));
const CustomerListComponent = lazy(() => import("./pages/customer/customerList"));
const CutomerViewComponent = lazy(() => import("./pages/customer/cutomerView"));
// order pages
const OrderDailysheetComponent = lazy(() => import("./pages/order/dailySheet"));
const OrderDailysheetDownloadPreviewComponent = lazy(() => import("./pages/order/dailySheet/downloadPreview"));
const OrderWorkOrderComponent = lazy(() => import("./pages/order/workOrder"));
const OrderComponent = lazy(() => import("./pages/order"));
const OrderListComponent = lazy(() => import("./pages/order/salesOrder"));
const OrderPendingListComponent = lazy(() => import("./pages/order/orderList"));
const CreateOrderComponent = lazy(() => import("./pages/order/salesOrder/create"));
const EditOrderComponent = lazy(() => import("./pages/order/salesOrder/edit"));
const ViewOrderComponent = lazy(() => import("./pages/order/salesOrder/view"));
const WorkOrderViewComponent = lazy(() => import("./pages/order/workOrder/workOrderView"));

// packing slip pages
const PackingSlipComponent = lazy(() => import("./pages/packingSlip"));
const PackingSlipCreateComponent = lazy(() => import("./pages/packingSlip/create"));
const PackingSlipEditComponent = lazy(() => import("./pages/packingSlip/edit"));
const PackingSlipViewComponent = lazy(() => import("./pages/packingSlip/view"));
const PackingSlipListComponent = lazy(() => import("./pages/packingSlip/list"));
const WaitingPackingSlipComponent = lazy(() => import("./pages/packingSlip/waitingPackingSlip"));
const CompletePackingSlipComponent = lazy(() => import("./pages/packingSlip/completePackingslip"));
const AllPackingSlipComponent = lazy(() => import("./pages/packingSlip/allPackingSlip"));
// supplier pages
const SupplierComponennt = lazy(() => import("./pages/suppliers"));
const SupplierListComponet = lazy(() => import("./pages/suppliers/supplier"));
const SupplierViewComponet = lazy(() => import("./pages/suppliers/supplier/view"));
// purchase order pages
const PurchaseOrderCreateComponent = lazy(() => import("./pages/suppliers/purchaseOrder/create"));
const PurchaseOrderUpdateComponent = lazy(() => import("./pages/suppliers/purchaseOrder/edit"));
const PurchaseOrderViewComponent = lazy(() => import("./pages/suppliers/purchaseOrder/view"));

const ReceivedPurchaseOrderViewComponent = lazy(() => import("./pages/suppliers/purchaseOrder/view/receivedPoView"));

const PurchaseOrderAllListComponent = lazy(() => import("./pages/suppliers/purchaseOrder/index"));
const PurchaseOrderWaitingListComponent = lazy(() => import("./pages/suppliers/purchaseOrder/waitingAprroval"));
const PurchaseOrderApprovedListComponent = lazy(() => import("./pages/suppliers/purchaseOrder/approved"));
const PurchaseOrderWaitingReceiveListComponent = lazy(() => import("./pages/suppliers/purchaseOrder/waitingReceive"));
const PurchaseOrderCompletedListComponent = lazy(() => import("./pages/suppliers/purchaseOrder/completed"));
const WaitingToReceivePurchaseOrder = lazy(() => import("./pages/suppliers/purchaseOrder/receivedPurchaseOrder"));

// material pages
const MaterialsComponent = lazy(() => import("./pages/materials"));
const AllMaterialsComponent = lazy(() => import("./pages/materials/allMaterials"));
const PalletComponent = lazy(() => import("./pages/materials/pallet"));
const PalletViewComponent = lazy(() => import("./pages/materials/pallet/palletView"));
const WoodComponent = lazy(() => import("./pages/materials/wood"));
const WoodViewComponent = lazy(() => import("./pages/materials/wood/woodView"));
const FastnersComponent = lazy(() => import("./pages/materials/fastners"));
const FastnerViewComponent = lazy(() => import("./pages/materials/fastners/fastnerView"));
// HT
const HTComponent = lazy(() => import("./pages/HT"));
const HTViewComponent = lazy(() => import("./pages/HT/views"));
const HTWorkOrderComponent = lazy(() => import("./pages/HT/htWorkOrder"));
const HTWorkOrderViewComponent = lazy(() => import("./pages/HT/htWorkOrderView"));

//Settings
const SettingsComponent = lazy(() => import("./pages/settings"));
const AcountList = lazy(() => import("./pages/settings/account/AcountList"));
const AccountCreate = lazy(() => import("./pages/settings/account/create"));
const AccountEdit = lazy(() => import("./pages/settings/account/edit"));
const AccountView = lazy(() => import("./pages/settings/account/create/views"));
const ActivityLog = lazy(() => import("./pages/settings/activityLog"));
const CustomerProfile = lazy(() => import("./pages/settings/companyProfile"));
//Form
const FormComponent = lazy(() => import("./pages/form"));

//LumberPrice
const LumberPrice = lazy(() => import("./pages/lumberPrice"));
const LumberPriceCreateComponent = lazy(() => import("./pages/lumberPrice/create"));
const LumberPriceEditComponent = lazy(() => import("./pages/lumberPrice/edit"));
const LumberPriceViewComponent = lazy(() => import("./pages/lumberPrice/view"));

//Quotations
const QuotationListComponent = lazy(() => import("./pages/quotations/quotation/list"));
const GenearteQuotationListComponent = lazy(() => import("./pages/quotations/generatedQuotation/list"));
const CreateQuotationComponent = lazy(() => import("./pages/quotations/quotation/create"));
const EditQuotationComponent = lazy(() => import("./pages/quotations/quotation/edit"));
const EditQuotationNewComponent = lazy(() => import("./pages/quotations/generatedQuotation/edit"));
const CreateExistingQuotationComponent = lazy(() => import("./pages/quotations/quotation/create_new"));
const QuotationViewComponent = lazy(() => import("./pages/quotations/quotation/view"));

// lumber calculator
const LumberCalculatorComponent = lazy(() => import("./pages/lumberCalculator"));

function App() {
	return (
		<Suspense
			fallback={
				<LoadingOverlay
					visible={true}
					loader={<Loader variant="oval" />}
					overlayBlur={1}
				/>
			}
		>
			<Router>
				<Routes>
					<Route
						path="/"
						element={<LoginComponent />}
					/>
					<Route
						path="/login"
						element={<LoginComponent />}
					/>
					<Route
						path="/admin/auth/reset-password"
						element={<ResetPassword />}
					/>
					<Route
						path="/admin/auth/forgot-password"
						element={<ForgotPassword />}
					/>
					<Route element={<AdminLayout />}>
						<Route
							path="/admin/dashboard"
							element={<DashboardComponent />}
						/>
						<Route
							path="/admin/change-password"
							element={<ChangePassword />}
						/>
						{/* customer Route */}
						<Route
							path="/admin/customer"
							element={<CustomerComponent />}
						/>
						<Route
							path="/admin/customers"
							element={<CustomerListComponent />}
						/>
						<Route
							path="/admin/customers/view/:cusID"
							element={<CutomerViewComponent />}
						/>
						{/* sales order Route */}
						<Route
							path="/admin/order"
							element={<OrderComponent />}
						/>
						<Route
							path="/admin/order/list"
							element={<OrderListComponent />}
						/>
						<Route
							path="/admin/order/list/pending"
							element={<OrderPendingListComponent />}
						/>
						<Route
							path="/admin/order/create"
							element={<CreateOrderComponent />}
						/>
						<Route
							path="/admin/order/edit/:orderID"
							element={<EditOrderComponent />}
						/>
						<Route
							path="/admin/order/view/:orderID"
							element={<ViewOrderComponent />}
						/>
						<Route
							path="/admin/order/dailysheet"
							element={<OrderDailysheetComponent />}
						/>
						<Route
							path="/admin/order/dailysheet/download-preview"
							element={<OrderDailysheetDownloadPreviewComponent />}
						/>
						<Route
							path="/admin/order/workorder"
							element={<OrderWorkOrderComponent />}
						/>
						<Route
							path="/admin/order/workorder/view/:orderID"
							element={<WorkOrderViewComponent />}
						/>

						{/* packing slip route */}
						<Route
							path="/admin/packing-slip"
							element={<PackingSlipComponent />}
						/>
						<Route
							path="/admin/packing-slip/list"
							element={<PackingSlipListComponent />}
						/>
						<Route
							path="/admin/packing-slip/create"
							element={<PackingSlipCreateComponent />}
						/>
						<Route
							path="/admin/packing-slip/edit/:orderID"
							element={<PackingSlipEditComponent />}
						/>
						<Route
							path="/admin/packing-slip/view/:orderID"
							element={<PackingSlipViewComponent />}
						/>
						<Route
							path="/admin/packing-slip/waiting-packingslip"
							element={<WaitingPackingSlipComponent />}
						/>
						<Route
							path="/admin/packing-slip/complete-packingslip"
							element={<CompletePackingSlipComponent />}
						/>
						<Route
							path="/admin/packing-slip/all-packingslip"
							element={<AllPackingSlipComponent />}
						/>
						{/* supplier route */}
						<Route
							path="/admin/suppliers"
							element={<SupplierComponennt />}
						/>
						<Route
							path="/admin/suppliers/list"
							element={<SupplierListComponet />}
						/>
						<Route
							path="/admin/suppliers/view/:supplierID"
							element={<SupplierViewComponet />}
						/>
						{/* purchase order route */}
						<Route
							path="/admin/purchase-order/create/:page"
							element={<PurchaseOrderCreateComponent />}
						/>
						<Route
							path="/admin/purchase-order/edit/:page/:poID"
							element={<PurchaseOrderUpdateComponent />}
						/>
						<Route
							path="/admin/purchase-order/view/:page/:poID"
							element={<PurchaseOrderViewComponent />}
						/>

						<Route
							path="/admin/purchase-order/received-po/:orderID/view/:poID"
							element={<ReceivedPurchaseOrderViewComponent />}
						/>

						{/* ReceivedPurchaseOrderViewComponent */}

						<Route
							path="/admin/purchase-order/all-po"
							element={<PurchaseOrderAllListComponent />}
						/>
						<Route
							path="/admin/purchase-order/waiting-po"
							element={<PurchaseOrderWaitingListComponent />}
						/>
						<Route
							path="/admin/purchase-order/approved-po"
							element={<PurchaseOrderApprovedListComponent />}
						/>
						<Route
							path="/admin/purchase-order/waiting-receive-po"
							element={<PurchaseOrderWaitingReceiveListComponent />}
						/>
						<Route
							path="/admin/purchase-order/completed-po"
							element={<PurchaseOrderCompletedListComponent />}
						/>
						<Route
							path="/admin/purchase-order/waiting-receive-po/receive-purchaseorder/:poID"
							element={<WaitingToReceivePurchaseOrder />}
						/>

						{/* materials Route */}
						<Route
							path="/admin/materials"
							element={<MaterialsComponent />}
						/>
						<Route
							path="/admin/all-materials"
							element={<AllMaterialsComponent />}
						/>
						<Route
							path="/admin/materials/pallet"
							element={<PalletComponent />}
						/>
						<Route
							path="/admin/materials/pallet/view/:palletID"
							element={<PalletViewComponent />}
						/>
						<Route
							path="/admin/materials/wood"
							element={<WoodComponent />}
						/>
						<Route
							path="/admin/materials/wood/view/:woodID"
							element={<WoodViewComponent />}
						/>
						<Route
							path="/admin/materials/fastners"
							element={<FastnersComponent />}
						/>
						<Route
							path="/admin/materials/fastners/view/:fastnerID"
							element={<FastnerViewComponent />}
						/>
						{/* Form */}
						<Route
							path="/admin/form"
							element={<FormComponent />}
						/>
						{/* HT Route */}
						<Route
							path="/admin/ht"
							element={<HTComponent />}
						/>
						<Route
							path="/admin/ht/:Httype"
							element={<HTViewComponent />}
						/>
						<Route
							path="/admin/ht/ht-wokorder"
							element={<HTWorkOrderComponent />}
						/>
						<Route
							path="/admin/ht-workorder/view/:orderID"
							element={<HTWorkOrderViewComponent />}
						/>

						{/* Settings */}
						<Route
							path="/admin/settings"
							element={<SettingsComponent />}
						/>
						<Route
							path="/admin/settings/accounts"
							element={<AcountList />}
						/>
						<Route
							path="/admin/settings/account/create"
							element={<AccountCreate />}
						/>
						<Route
							path="/admin/settings/account/:accountId"
							element={<AccountEdit />}
						/>
						<Route
							path="/admin/settings/account/view/:accountId"
							element={<AccountView />}
						/>
						<Route
							path="/admin/settings/activity-log"
							element={<ActivityLog />}
						/>
						<Route
							path="/admin/settings/customer-profile"
							element={<CustomerProfile />}
						/>

						{/* LumberPrice */}
						<Route
							path="/admin/lumberprice"
							element={<LumberPrice />}
						/>
						<Route
							path="/admin/lumberprice/create"
							element={<LumberPriceCreateComponent />}
						/>
						<Route
							path="/admin/lumberprice/edit/:lumberID/:seprateID"
							element={<LumberPriceEditComponent />}
						/>
						<Route
							path="/admin/lumberprice/view/:lumberID/:seprateID"
							element={<LumberPriceViewComponent />}
						/>
						<Route
							path="/admin/auth/reset-password"
							element={<></>}
						/>
						{/* Quation */}
						<Route
							path="/admin/quotations/Pending"
							element={<QuotationListComponent />}
						/>
						<Route
							path="/admin/quotations/create"
							element={<CreateQuotationComponent />}
						/>
						<Route
							path="/admin/quotations/edit/:quotationID"
							element={<EditQuotationComponent />}
						/>
						<Route
							path="/admin/quotations/collaboration/edit/:quotationID"
							element={<EditQuotationNewComponent />}
						/>
						<Route
							path="/admin/quotations/:status"
							element={<GenearteQuotationListComponent />}
						/>
						<Route
							path="/admin/quotations/create_new/:quotationID"
							element={<CreateExistingQuotationComponent />}
						/>
						<Route
							path="/admin/quotations/view/:status/:quotationID"
							element={<QuotationViewComponent />}
						/>

						{/* LumberCalculator */}
						<Route
							path="/admin/lumber-calculator"
							element={<LumberCalculatorComponent />}
						/>
						<Route
							path="/new-form"
							element={<NewForm />}
						/>
					</Route>
				</Routes>
			</Router>
		</Suspense>
	);
}

export default App;
