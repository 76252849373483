import axios from "axios";
import {
	getSessionStorage,
	//  setSessionStorage,
	//   unsetSessionStorage
} from "../helpers/sessionStorage";
import { SESSION } from "../helpers/global";

const API = axios.create({
	baseURL: process.env.REACT_APP_BASE_API,
});
// let isRefreshing = false;
const TOKEN_PAYLOAD_KEY = "authorization";
// const PUBLIC_REQUEST_KEY = "public-request";
const TOKEN = () => getSessionStorage(SESSION.SANDB_TOKEN);
// const errorMessage = {
//     success: false,
//     message: "Opss! The Secssio is Closed",
// };

// export const refetchToken = async () => {
// 	const errorMessage = {
// 		success: false,
// 		message: "Time Out! Kindly Login",
// 	};
// 	axios({
// 		url: process.env.REACT_APP_BASE_API + "admin/refresh",
// 		method: "POST",
// 		headers: {
// 			Authorization: "Bearer " + getSessionStorage(SESSION.CTS_REFRESH_TOKEN),
// 		},
// 	})
// 		.then((response) => {
// 			setSessionStorage(SESSION.CTS_TOKEN, response.data.authorisation.token);
// 			setSessionStorage(SESSION.CTS_EMPLOYEEID, response.data.user.id);
// 			setSessionStorage(SESSION.CTS_REFRESH_TOKEN, response.data.refreshtoken.refresh_token);
// 			window.location.href = window.location.pathname;
// 		})
// 		.catch((error) => {
// 			notify(errorMessage);
// 			unsetSessionStorage();
// 			setTimeout(() => {
// 				window.location.href = "/login";
// 			}, 1000);
// 		});
// };

API.interceptors.request.use(function (config) {
	if (TOKEN()) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${TOKEN()}`;
	}
	// config["headers"]["ngrok-skip-browser-warning"] = "69420";
	return config;
});

API.interceptors.response.use(
	// unwrap response data
	({ data }) => data,

	// catch statusCode != 200 responses and format error
	(error) => {
		if (error?.response) {
			if (error.response.status === 401) {
				sessionStorage.clear();
				localStorage.clear();
				// notify({
				// 	status: false,
				// 	error: {
				// 		token_exp: ["Please Login"],
				// 	},
				// });
				const url = "/" + String(window.location.href).split("/").splice(3).join("/");

				window.location.href = `/login?callback=${url}`;
				// setTimeout(() => {
				// 	window.location.href = "/login";
				// }, 1000);
				return Promise.reject(error.response);
			} else {
				return Promise.reject(error?.response?.data);
			}
			// if (error.response.status === 403) {
			// if (!isRefreshing) {
			// isRefreshing = true;
			// refetchToken();
			// }
			// }
		} else if (error?.message === "Network Error") {
			return Promise.reject(error);
		} else {
			return Promise.reject(error);
		}
	}
);

export default API;
