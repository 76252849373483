import { createSlice } from "@reduxjs/toolkit";
import { formDocList, formDocUpload, formDocumentDelete } from "redux/service/formService";

const formCasess = [
	{
		api: formDocList,
		name: "formDocList",
	},
	{
		api: formDocUpload,
		name: "formDocUpload",
	},
	{
		api: formDocumentDelete,
		name: "formDocumentDelete",
	},
];

let initialState = {};
formCasess.forEach((api) => {
	initialState[api.name] = {
		loading: false,
		data: null,
		error: null,
	};
});

export const formSlice = createSlice({
	name: "form",
	initialState,
	extraReducers: (builder) => {
		formCasess.forEach((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload?.data ?? null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].error = null;
					state[cases.name].data = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].error = payload;
					state[cases.name].data = null;
				});
		});
		return;
	},
});

export default formSlice.reducer;
