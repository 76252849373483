import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./slice/authSlice";
import { essentialSlice } from "./slice/essentialSlice";
import { customerSlice } from "./slice/customerSlice";
import { woodSlice } from "./slice/woodSlice";
import { fastnersSlice } from "./slice/fastnerSlice";
import { palletSlice } from "./slice/palletSlice";
import { orderSlice } from "./slice/orderSlice";
import { htSlice } from "./slice/htSlice";
import { settingSlice } from "./slice/settingSlice";
import { activityLogSlice } from "./slice/activityLogSlice";
import { formSlice } from "./slice/formSlice";
import { supplierSlice } from "./slice/supplierSlice";
import { purchaseOrderSlice } from "./slice/purchaseOrderSlice";
import { lumberPriceSlice } from "./slice/lumberPriceSlice";
import { quotationSlice } from "./slice/quotationSlice";
import { lumberCalculatorSlice } from "./slice/lumberCalculatorSlice";
import { emailDownloadSlice } from "./slice/EmailDownloadSlice";

const reducers = combineReducers({
	auth: authSlice.reducer,
	essential: essentialSlice.reducer,
	customer: customerSlice.reducer,
	wood: woodSlice.reducer,
	fastners: fastnersSlice.reducer,
	pallet: palletSlice.reducer,
	order: orderSlice.reducer,
	ht: htSlice.reducer,
	settings: settingSlice.reducer,
	activityLog: activityLogSlice.reducer,
	form: formSlice.reducer,
	supplier: supplierSlice.reducer,
	purchaseOrder: purchaseOrderSlice.reducer,
	quotation: quotationSlice.reducer,
	lumberPrice: lumberPriceSlice.reducer,
	lumberCalculator: lumberCalculatorSlice.reducer,
	emailDownload: emailDownloadSlice.reducer
});

export default reducers;
