import { createAsyncThunk } from "@reduxjs/toolkit";
import { downloadFile, downloadFileExcel, printFile, printFileView } from "helpers/global";
import API from "redux/api";

export const htDocumentDownload = createAsyncThunk("documentDownload", async (params, thunkAPI) => {
	const { id = "", name } = params;
	try {
		const response = await API.get(
			`/ht/${id}`,
			{
				responseType: "blob",
			},
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/pdf",
				},
			}
		);
		downloadFile(response, name);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const fileHandlerDownload = createAsyncThunk("fileDownload", async (bodyParams, thunkAPI) => {
	const { url, name, print, separate_id, all_record, order_action, ht_status, single_view, printOnly, excel } = bodyParams;
	try {
		// Assuming you are making a POST request to /filehandler/${url}
		const response = await API.post(
			`/filehandler/${url}`,
			{
				separate_id,
				all_record,
				order_action,
				ht_status,
				single_view,
			},
			{
				responseType: "arraybuffer",

			}
		);
		if (print) {
			excel ? downloadFileExcel(response) : printOnly ? printFileView(response) : printFile(response);
		} else {
			downloadFile(response, name);
		}
	} catch (error) {
		let message = String.fromCharCode.apply(
			null,
			new Uint8Array(error));
		return thunkAPI.rejectWithValue(JSON.parse(message));
	}
});

export const fileHandlerDownloadExcel = createAsyncThunk("fileDownload", async (bodyParams, thunkAPI) => {
	const { url, name, print, separate_id, all_record, order_action, ht_status, single_view, printOnly, excel } = bodyParams;
	try {
		// Assuming you are making a POST request to /filehandler/${url}
		const response = await API.post(
			`/filehandler/${url}`,
			{
				separate_id,
				all_record,
				order_action,
				ht_status,
				single_view,
			},
			{
				responseType: "arraybuffer",
			}
		);
		downloadFileExcel(response, name);
	} catch (error) {
		let message = String.fromCharCode.apply(
			null,
			new Uint8Array(error));
		return thunkAPI.rejectWithValue(JSON.parse(message));
	}
});

export const fileHandlerEmail = createAsyncThunk("sendEmail", async (bodyParams, thunkAPI) => {
	const { url, separate_id, order_action, ht_status, cust_name, cust_email, cust_sender_name, cust_subject } =
		bodyParams;
	try {
		const response = await API.post(`/filehandler/${url}/pdf`, {
			single_view: true,
			separate_id,
			order_action,
			ht_status,
			cust_name,
			cust_email,
			cust_sender_name,
			cust_subject,
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
