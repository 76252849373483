import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// purchase order service

export const purchaseOrderListService = createAsyncThunk("purchaseOrderList", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/purchase-order?status=${bodyParams.type}&per_page=${bodyParams.per_page}&search=${bodyParams.search
			}&page=${bodyParams.page}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const purchaseOrderViewService = createAsyncThunk("purchaseOrderView", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/purchase-order/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});


export const purchaseOrderReceivedPOViewService = createAsyncThunk("purchaseOrderReceivedPoView", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/purchase-order/${bodyParams?.order_id}?receiver_id=${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const purchaseOrderCreateService = createAsyncThunk("purchaseOrderCreate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/purchase-order`, bodyParams, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const purchaseOrderUpdateService = createAsyncThunk("purchaseOrderUpdate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/purchase-order/${bodyParams?.id}`, bodyParams, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const purchaseOrderDeleteService = createAsyncThunk("purchaseOrderDelete", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.delete(`/purchase-order/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const purchaseOrderStatusUpdateService = createAsyncThunk(
	"purchaseOrderStatusUpdate",
	async (bodyParams, thunkAPI) => {
		try {
			const response = await API.post(
				`/purchase-order/action/${bodyParams?.id}?action=${bodyParams?.action}`,
				bodyParams?.formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);
