import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "redux/api";

export const lumberCalculatorDeleteService = createAsyncThunk("lumberCalculatorDeleteService", async (id, thunkAPI) => {
	try {
		const response = await API.delete(`/lumbercalc/${id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
}); 

export const lumberCalculatorDetailDeleteService = createAsyncThunk("lumberCalculatorDetailDeleteService", async (id, thunkAPI) => {
	try {
		const response = await API.delete(`/lumbercalc/detail/${id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const lumberCalculatorUpdateService = createAsyncThunk( "lumberCalculatorUpdateService", async( { formData, productId}, thunkAPI ) => {
	try {
		const response = await API.post(`/lumbercalc/${productId}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			  },
		});
		return response
	} catch (error) {
		return thunkAPI.rejectWithValue(error)
	}
})


export const lumberCalculatorViewService = createAsyncThunk( "lumberCalculatorViewService", async( productId = "", thunkAPI ) => {
    try {
        const response = await API.get( `/lumbercalc/${productId}` )
        return response;
    } catch ( error ) {
        return thunkAPI.rejectWithValue( error )
    }
}) 

export const lumberCalculatorListService = createAsyncThunk("lumberCalculatorListService", async (params, thunkAPI) => {
	try {
		const response = await API.get(`/lumbercalc`, {
			params,
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const lumberCalculatorAddService = createAsyncThunk( "lumberCalculatorAddService", async( params, thunkAPI ) => {
    try {
        const response = await API.post( `/lumbercalc`, params )
        return response;
    } catch ( error ) {
        return thunkAPI.rejectWithValue( error )
    }
})

