import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// get dashboard top5 service

export const getDashboardListService = createAsyncThunk("dashboardList", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/dashboard`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// essential service

export const essentialService = createAsyncThunk("essential", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/essential?include=${bodyParams}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const essentialServiceByCompany = createAsyncThunk("essentialByCompany", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/essential?include=${bodyParams?.name}&customer_id=${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const essentialCreateService = createAsyncThunk("essentialCreate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/${bodyParams?.url}`, bodyParams?.data);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const essentialDeleteService = createAsyncThunk("essentialDelete", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.delete(`/${bodyParams?.url}/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const addCustomerAddress = createAsyncThunk("addCustomerAddress", async (data, thunkAPI) => {
	try {
		const response = await API.post(`/customer-address`, data, {
			headers: { "Content-Type": "multipart/form-data" },
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const documentDeleteService = createAsyncThunk("documentDeleteService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.delete(`/filehandler/remove_file/sale-pack/${bodyParams?.id}/${bodyParams?.deleteID}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
