import { createSlice } from "@reduxjs/toolkit";
import { allMaterialListService, palletCreateService, palletDeleteService, palletListService, palletUpdateService, palletViewService } from "redux/service/palletService";

const palletCases = [
  {
    api: palletCreateService,
    name: "palletCreateService",
  },
  {
    api: palletUpdateService,
    name: "palletUpdateService",
  },
  {
    api: palletListService,
    name: "palletListService",
  },
  {
    api: palletDeleteService,
    name: "palletDeleteService",
  },
  {
    api: palletViewService,
    name: "palletViewService",
  },
  {
    api: allMaterialListService,
    name: "allMaterialListService",
  }
];

let initialState = {};
palletCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const palletSlice = createSlice({
  name: "customer",
  initialState,
  extraReducers: (builder) => {
    palletCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
          state[cases.name].data = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
          state[cases.name].data = null;
        });
    });
    return;
  },
});

export default palletSlice.reducer;
