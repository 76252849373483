import { createSlice } from "@reduxjs/toolkit";
import {
	quotationCreateService,
	quotationDeleteService,
	quotationListService,
	quotationUpdateService,
	quotationViewService,
	quotationStatusUpdateService,
	generateQuotationDeleteService,
	generateQuotationService,
	generateQuotationViewService,
	generateQuotationSingleDeleteService,
	generateQuotationUpdateService,
	generateQuotationList,
} from "redux/service/quotationService";

const quotationCases = [
	{
		api: quotationCreateService,
		name: "quotationCreateService",
	},
	{
		api: quotationUpdateService,
		name: "quotationUpdateService",
	},
	{
		api: quotationListService,
		name: "quotationListService",
	},
	{
		api: quotationDeleteService,
		name: "quotationDeleteService",
	},
	{
		api: quotationViewService,
		name: "quotationViewService",
	},
	{
		api: quotationStatusUpdateService,
		name: "quotationStatusUpdateService",
	},
	{
		api: generateQuotationDeleteService,
		name: "generateQuotationDeleteService",
	},
	{
		api: generateQuotationSingleDeleteService,
		name: "generateQuotationSingleDeleteService",
	},
	{
		api: generateQuotationService,
		name: "generateQuotationService",
	},
	{
		api: generateQuotationUpdateService,
		name: "generateQuotationUpdateService",
	},
	{
		api: generateQuotationViewService,
		name: "generateQuotationViewService",
	},
	{
		api: generateQuotationList,
		name: "generateQuotationList",
	},
];

let initialState = {};
quotationCases.forEach((api) => {
	initialState[api.name] = {
		loading: false,
		data: null,
		error: null,
	};
});

export const quotationSlice = createSlice({
	name: "quotation",
	initialState,
	extraReducers: (builder) => {
		quotationCases.forEach((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload?.data ?? null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].error = null;
					state[cases.name].data = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].error = payload;
					state[cases.name].data = null;
				});
		});
		return;
	},
});

export default quotationSlice.reducer;
