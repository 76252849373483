import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

export const activityLogListService = createAsyncThunk("activityLogListService", async (params, thunkAPI) => {
	try {
		const response = await API.get(`/log`, {
			params,
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
