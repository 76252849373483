import { createSlice } from "@reduxjs/toolkit";
import {
  supplierCreateService, supplierDeleteService, supplierListService, supplierUpdateService, supplierViewService,
  supplierPOViewService
} from "redux/service/supplierService";

const supplierCases = [
  {
    api: supplierCreateService,
    name: "supplierCreateService",
  },
  {
    api: supplierUpdateService,
    name: "supplierUpdateService",
  },
  {
    api: supplierListService,
    name: "supplierListService",
  },
  {
    api: supplierDeleteService,
    name: "supplierDeleteService",
  },
  {
    api: supplierViewService,
    name: "supplierViewService",
  },
  {
    api: supplierPOViewService,
    name: "supplierPOViewService"
  }
];

let initialState = {};
supplierCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  extraReducers: (builder) => {
    supplierCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
          state[cases.name].data = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
          state[cases.name].data = null;
        });
    });
    return;
  },
});

export default supplierSlice.reducer;
