import React from 'react'
import { AppShell } from '@mantine/core'
import { Outlet } from 'react-router-dom'
import HeaderComponent from './header'

function AdminLayout() {
    return (
        <AppShell
            padding={'0px'}
            styles={() => ({
                main: { backgroundColor: "#ededed" },
            })}
            sx={{
                overflow: 'hidden'
            }}
        >
            <HeaderComponent />
            <div>
                <Outlet />
            </div>
        </AppShell>
    )
}

export default AdminLayout