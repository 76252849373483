/* eslint-disable no-unused-vars */
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";
import { Image } from "@mantine/core";
import { HtPdfIcon } from "./images";
const key = process.env.REACT_APP_HASH_DECODE_KEY;

export const SESSION = {
	SANDB_TOKEN: "SANDB_TOKEN",
};

export const notify = (props) => {
	const errorObj = props?.error || props?.message;
	switch (props.status) {
		case true:
			notifications.show({
				id: "sucess",
				withCloseButton: true,
				autoClose: 3000,
				message: `${props.message}`,
				color: "green",
				className: "my-notification-class",
				loading: false,
			});
			break;
		case false:
			if (typeof errorObj === "object") {
				for (const key in errorObj) {
					notifications.show({
						withCloseButton: true,
						autoClose: 3000,
						message: errorObj[key][0],
						color: "red",
						className: "my-notification-class",
					});
				}
			} else if (typeof errorObj === "string") {
				notifications.show({
					withCloseButton: true,
					autoClose: 3000,
					message: errorObj,
					color: "red",
					className: "my-notification-class",
				});
			} else {
				notifications.show({
					withCloseButton: true,
					autoClose: 3000,
					message: errorObj,
					color: "red",
					className: "my-notification-class",
				});
			}
			break;
		default:
			notifications.show({
				id: "sucess",
				withCloseButton: true,
				autoClose: 3000,
				message: "Something Went Wrong",
				color: "red",
				className: "my-notification-class",
				loading: false,
			});
			break;
	}
};

export const AddressFormater = (place) => {
	let country,
		province,
		city,
		line1,
		route,
		postal_code = null;
	if (place?.address_components !== undefined) {
		let addrComp = place.address_components;
		for (let i = 0; i < addrComp.length; ++i) {
			var typ = addrComp[i].types;
			if (compIsType(typ, "administrative_area_level_1"))
				province = addrComp[i].long_name; //store the province
			else if (compIsType(typ, "locality")) city = addrComp[i].long_name; //store the city
			else if (compIsType(typ, "route")) route = addrComp[i].long_name; //store the street
			else if (compIsType(typ, "street_number")) line1 = addrComp[i].long_name; //store the street number
			else if (compIsType(typ, "country")) country = addrComp[i].long_name; //store the country
			else if (compIsType(typ, "postal_code")) postal_code = addrComp[i].long_name; //store the postal_code
			//we can break early if we find all three data
			if (
				province != null &&
				city != null &&
				country != null &&
				postal_code != null &&
				line1 != null &&
				route != null
			)
				break;
		}
		return {
			address: place.formatted_address,
			// country: country,
			province: province,
			city: city,
			postal_code: postal_code,
			// street_number: line1,
			street: line1 + route,
			latitude: place.geometry.location.lat(),
			longitude: place.geometry.location.lng(),
		};
	}
};
export const compIsType = (t, s) => {
	for (let z = 0; z < t.length; ++z) if (t[z] === s) return true;
	return false;
};

export const CheckYesNo = (value) => {
	if (value === true) {
		return 1;
	} else {
		return 0;
	}
};
const displayDateFormat = "YYYY/MM/DD";

export const convertDate = (date) => {
	date = dayjs(date).format(displayDateFormat);
	return date;
};

export const unitPriceConvertor = (splitValue, value) => {
	if (splitValue?.slice(-4) === "0000") {
		return Number(value).toFixed(2);
	} else if (splitValue?.slice(-3) === "000") {
		return Number(value).toFixed(2);
	} else if (splitValue?.slice(-2) === "00") {
		return Number(value).toFixed(2);
	} else if (splitValue?.slice(-1) === "0") {
		return Number(value).toFixed(3);
	} else if (splitValue === undefined) {
		return Number(value).toFixed(2);
	} else {
		return Number(value).toFixed(4);
	}
};

export const decrypt = (payload) => {
	let encryptStr = CryptoJS.enc.Base64.parse(payload);
	let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
	encryptData = JSON.parse(encryptData);
	let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
	var decrypted = CryptoJS.AES.decrypt(encryptData.value, CryptoJS.enc.Base64.parse(key), {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	return CryptoJS.enc.Utf8.stringify(decrypted);
};

export const downloadFile = (response, name) => {
	const blob = new Blob([response], { type: "application/pdf" });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = name;
	a.click();
};

export const printFileView = (response) => {
	const blob = new Blob([response], { type: "application/pdf" });
	const url = URL.createObjectURL(blob);
	const printWindow = window.open(url);
};

export const printFile = (response) => {
	const blob = new Blob([response], { type: "application/pdf" });
	const url = URL.createObjectURL(blob);
	const printWindow = window.open(url);
	printWindow.onload = () => {
		printWindow.print();
	};
};

export const downloadFileExcel = (response, name) => {
	const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = name;
	a.click();
};

export const lumberFormula = (type, watch, index) => {
	const { details } = watch();
	const { thick, wide, long, price, fbm, pieces } = details[index];

	switch (Number(type)) {
		//Feet FBM To Number(pieces)
		case 0:
			return {
				calculateEach: (((Number(thick) * Number(wide) * Number(long)) / 12) * Number(price)) / 1000,
				calculatepieces: Number(fbm) / ((Number(thick) * Number(wide) * Number(long)) / 12),
				calculateTotal:
					((((Number(thick) * Number(wide) * Number(long)) / 12) * Number(price)) / 1000) * (Number(fbm) / ((Number(thick) * Number(wide) * Number(long)) / 12)),
			};
		//Inch FBM To Number(pieces)
		case 1:
			return {
				calculateEach: (((Number(thick) * Number(wide) * Number(long)) / 12 / 12) * Number(price)) / 1000,
				calculatepieces: Number(fbm) / ((Number(thick) * Number(wide) * Number(long)) / 12 / 12),
				calculateTotal:
					((((Number(thick) * Number(wide) * Number(long)) / 12 / 12) * Number(price)) / 1000) *
					(Number(fbm) / ((Number(thick) * Number(wide) * Number(long)) / 12 / 12)),
			};
		//Feet Number(pieces) To FBM
		case 2:
			return {
				calculateEach: (((Number(thick) * Number(wide) * Number(long)) / 12) * Number(price)) / 1000,
				calculatepieces: Number(pieces) * ((Number(thick) * Number(wide) * Number(long)) / 12),
				calculateTotal:
					((((Number(thick) * Number(wide) * Number(long)) / 12) * Number(price)) / 1000) * (Number(pieces) * ((Number(thick) * Number(wide) * Number(long)) / 12)),
			};
		// Inch Number(pieces) to FBM
		case 3:
			return {
				calculateEach: (((Number(thick) * Number(wide) * Number(long)) / 12 / 12) * Number(price)) / 1000,
				calculatepieces: Number(pieces) * ((Number(thick) * Number(wide) * Number(long)) / 12 / 12),
				calculateTotal:
					((((Number(thick) * Number(wide) * Number(long)) / 12 / 12) * Number(price)) / 1000) *
					(Number(pieces) * ((Number(thick) * Number(wide) * Number(long)) / 12 / 12)),
			};
		//Feet FBM to Number(pieces)
		default:
			return {
				calculateEach: (((Number(thick) * Number(wide) * Number(long)) / 12) * Number(price)) / 1000,
				calculatepieces: Number(fbm) / ((Number(thick) * Number(wide) * Number(long)) / 12),
				calculateTotal:
					((((Number(thick) * Number(wide) * Number(long)) / 12 / 12) * Number(price)) / 1000) *
					((Number(pieces) * (Number(thick) * Number(wide) * Number(long))) / 12),
			};
	}
};

export const findDocumentName = (document) => {
	return String(document).split("/").slice(-1)?.[0] || "";
};

export const findDocumentType = (document) => {
	return String(document).split(".").slice(-1)[0].toLocaleLowerCase();
};

export const DocumentString = (path) => {
	const ImagePath = process.env.REACT_APP_API_IMAGE_ENDPOINT + "/" + path;
	const key = findDocumentType(path);
	switch (key) {
		case "jpg":
			return (
				<>
					<Image
						src={ImagePath}
						sx={{
							// padding: "5px 0 0 0",
							maxHeight: "100px",
							maxWidth: "100px",
						}}
					/>
					{/* <span>JPG</span> */}
				</>
			);
		case "jpeg":
			return (
				<>
					<Image
						src={ImagePath}
						sx={{
							// padding: "5px 0 0 0",
							maxHeight: "108px",
							maxWidth: "100px",
						}}
					/>
					{/* <span>JEPG</span> */}
				</>
			);
		case "png":
			return (
				<>
					<Image
						src={ImagePath}
						sx={{
							// padding: "5px 0 0 0",
							maxHeight: "100px",
							maxWidth: "100px",
						}}
					/>
					{/* <span>PNG</span> */}
				</>
			);
		default:
			return (
				<>
					<Image
						src={HtPdfIcon}
						sx={{
							maxHeight: "45px",
							maxWidth: "34px",
						}}
					/>
					<span>PDF</span>
				</>
			);
	}
};

export const priceConventer = (price) => {
	return price && !isNaN(price) ? parseFloat(price).toFixed(2) : Number().toFixed(2);
};

export function convertRequestData(date) {
	return dayjs(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
}

export const handleInput = (e) => {
	const inputValue = e.target.value;
	e.target.value = inputValue.replace(/[^0-9.-]/g, ""); // Remove non-numeric characters
};

export function changeLabelToAddress(addressArray = []) {
	return addressArray?.map((address) => {
		return {
			...address,
			address: address?.label || "",
			id: address?.value || "",
			unit: address?.units?.label || "",
		};
	});
}

export function sendDefaultAddress(formData, arrayAddress = [], default_name, unit_name) {
	if (formData?.[default_name]?.address) {
		return {
			[default_name]: formData?.[default_name]?.address || null,
			[unit_name]: formData?.[default_name]?.unit || null,
		};
	} else if (formData?.[default_name]) {
		return {
			[default_name]: formData?.[default_name] || null,
			[unit_name]: formData?.[unit_name] || null,
		};
	} else if (arrayAddress?.length >= 1) {
		const length = arrayAddress?.length - 1;

		return {
			[default_name]: arrayAddress[length]?.address || null,
			[unit_name]: arrayAddress[length]?.unit || null,
		};
	} else {
		return {
			[default_name]: null,
			[unit_name]: null,
		};
	}
}
