import { createStyles } from '@mantine/core';


const Styles = createStyles((theme) => ({
  tab: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    padding: theme.fontSizes.md,
    color: "#302F46",

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    '&:not(:first-of-type)': {
      borderLeft: 1,
    },
    '&[data-active]:hover': {
      backgroundColor: '#EEF6FF',
    },
    '&[data-active]': {
      backgroundColor: '#D6E5F9',
      borderColor: '#D6E5F9',
      color: '#1E55A5',
    },
  },
  tabIcon: {
    marginRight: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
  },

  tabsList: {
    display: 'flex',
    margin: "1%",
    width: '15%',
  },

}))

export default Styles