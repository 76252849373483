import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// Admin login
export const authLogin = createAsyncThunk("login", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/auth/login`, bodyParams);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const authLogout = createAsyncThunk("logout", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/auth/logout`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const getMe = createAsyncThunk("getMe", async (params, thunkAPI) => {
	try {
		const response = await API.get(`/auth/me`, {
			params,
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const authforgotPassword = createAsyncThunk("forgotPassword", async (data, thunkAPI) => {
	try {
		const response = await API.post(`/forgot-password`, data);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const authResetPassword = createAsyncThunk("resetPassword", async (data, thunkAPI) => {
	try {
		const response = await API.post(`/reset-password`, data);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const authChangepassword = createAsyncThunk("authChangepassword", async (data, thunkAPI) => {
	try {
		const response = await API.post(`/account/change/password`, data);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
