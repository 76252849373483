import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// wood service

export const woodListService = createAsyncThunk(
  'woodList',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/meterial-wood?per_page=${bodyParams.per_page}&search=${bodyParams.search}&page=${bodyParams.page}&sort=${
        bodyParams?.sort || "desc"
      }&sort_column=${bodyParams?.sort_column || ""}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

export const woodViewService = createAsyncThunk(
  'woodView',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/meterial-wood/${bodyParams.id}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

export const woodCreateService = createAsyncThunk(
  'woodCreate',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/meterial-wood`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

export const woodUpdateService = createAsyncThunk(
  'woodUpdate',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/meterial-wood/${bodyParams?.id}`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

export const woodDeleteService = createAsyncThunk(
  'woodDelete',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/meterial-wood/${bodyParams?.id}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
