import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// fastner service

export const fastnerListService = createAsyncThunk("fastnerList", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/meterial-fastner?per_page=${bodyParams.per_page}&search=${bodyParams.search}&page=${
				bodyParams.page
			}&sort=${bodyParams?.sort || "desc"}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const fastnerViewService = createAsyncThunk("fastnerView", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/meterial-fastner/${bodyParams.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const fastnerCreateService = createAsyncThunk("fastnerCreate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/meterial-fastner`, bodyParams);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const fastnerUpdateService = createAsyncThunk("fastnerUpdate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.put(`/meterial-fastner/${bodyParams?.id}`, bodyParams);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const fastnerDeleteService = createAsyncThunk("fastnerDelete", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.delete(`/meterial-fastner/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
